import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ErrorFormService, ErrorsMap, IErrorMessage, IQuestionnaireQuestionDto } from 'repository';

@Component({
  selector: 'lib-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionnaireComponent implements OnChanges {

  @Input() form: FormGroup;
  @Input() showLogin = true;
  @Input() questions: IQuestionnaireQuestionDto[] = [];

  @Output() loginEvent = new EventEmitter();
  @Output() sendFormEvent = new EventEmitter();

  public errorMessages: ErrorsMap<IErrorMessage> = {};
  public errors: ErrorsMap<string> = {};

  constructor(private fb: FormBuilder,
              private readonly _errorFormService: ErrorFormService
  ) {
  }

  ngOnChanges() {
    this.buildForm();
  }

  buildForm() {
    if (this.questions?.length > 0) {
      this.questions.forEach(field => {
        const control = this.fb.control('', [Validators.required, this.trimValidator]);

        this.errorMessages['response_' + field.id] = {
          required: 'Answer is required'
        };

        this.form.addControl('response_' + field.id, control);
      });
    }

  }

  login(): void {
    this.loginEvent.emit();
  }

  test(): void {

    if (this.form.invalid) {
      this.errors = this._errorFormService.verifyError(this.form, this.errorMessages);

      return;
    }

    const outputArray = Object.keys(this.form.value).map(key => {
      return {
        // tslint:disable-next-line:radix
        questionnaireQuestionId: parseInt(key.split('_')[1]),
        answer: this.form.value[key]
      };
    });

    this.sendFormEvent.emit(outputArray);

  }

  private readonly trimValidator = (
    control: FormControl
  ): ValidationErrors | null => {
    if (control.value) {
      // Обрезаем начальные и конечные пробелы с помощью регулярного выражения
      const trimmedValue = control.value.replace(/^\s+|\s+$/g, '');

      // Если обрезанное значение отличается от введенного, обновляем контрол
      if (trimmedValue !== control.value) {
        control.setValue(trimmedValue, { emitEvent: false });
      }

      // Проверка на пустое значение после обрезки пробелов
      if (trimmedValue.length === 0) {
        return { required: true };
      }
    }

    // Возвращаем null, если нет ошибок
    return null;
  };

}
