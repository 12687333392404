import { Component, OnInit } from '@angular/core';
import {
  ErrorFormService,
  ErrorsMap,
  IErrorMessage,
  IExistAffiliate, IQuestionnaireQuestionDto,
  NotificationService,
  PartnershipRequestStatus,
  TypeMessage
} from 'repository';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, distinctUntilChanged, finalize, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { UserCabinetService } from './../../lib/user-cabinet.service';
import { MatDialogRef } from '@angular/material/dialog';
import { User } from 'user';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from 'localization';

@UntilDestroy()
@Component({
  selector: 'lib-add-new-affiliate-code',
  templateUrl: './add-new-affiliate-code.component.html',
  styleUrls: ['./add-new-affiliate-code.component.scss']
})
export class AddNewAffiliateCodeComponent implements OnInit {

  showAffiliateWindow$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  loadQuestionList$: Observable<IQuestionnaireQuestionDto[]> =
    this._userCabinetService.getQuestionnaire(this._translateService.language.langId).pipe(
      finalize(() => {
        this.showLoader$.next( false);
      })
    );

  public readonly errorMessages: ErrorsMap<IErrorMessage> = {
    AffiliateCode: {
      required: 'Affiliate code is required',
      codeIsExist:
        'This code is already taken, please insert a different code.',
      maxlength: 'Max length 30 symbol',
      pattern: 'Can contain only uppercase letters and numbers'
    },
    acceptAffiliateTermsConditions: {
      required: 'Please accept Affiliate Terms & Conditions'
    },
    companyName: {
      required: 'Company name cannot be empty',
      maxlength: 'Max length 35 symbol',
      minlength: 'Min length 3 symbol'
    }
  };

  form: FormGroup;
  public formQuestionnaire: FormGroup = this._formBuilder.group({});

  public showLoader$ = new BehaviorSubject<boolean>(false);

  public errors: ErrorsMap<string> = {};
  public affiliateErrorCode = false;

  constructor(
    private readonly _errorFormService: ErrorFormService,
    private readonly _userCabinetService: UserCabinetService,
    private readonly notification: NotificationService,
    private readonly _translateService: TranslateService,
    private readonly _user: User,
    private readonly _dialogRef: MatDialogRef<AddNewAffiliateCodeComponent>,
    private readonly _formBuilder: FormBuilder
  ) {
  }

  public get isCompanyName(): boolean {
    return !!this._user?.companyName ?? false;
  }

  public ngOnInit(): void {
    this.buildForm();
    this.getWindowInfo();
  }

  public buildForm = (): void => {
    this.form = new FormGroup({
      AffiliateCode: new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern('[A-Z0-9]*$'),
          Validators.maxLength(30)
        ],
        [this.validateAffiliateCode, this.validateExistAffiliateCode]
      ),
      companyName: new FormControl(
        null,
        [
          Validators.required,
          Validators.maxLength(35),
          Validators.minLength(3),
          this._trim
        ]
      ),
      acceptAffiliateTermsConditions: new FormControl(
        false,
        [Validators.required, this._checkAcceptAffiliateTermsConditions]
      )
    });

    this.form
      .get('companyName')
      .setValue(this._user.companyName);

  };

  // tslint:disable-next-line:no-any
  public addNewCode(responseList: any[]) {

    this.showAffiliateWindow$.next('create');
    this.showLoader$.next(true);

    this._userCabinetService.changeCompanyName(this.form.get('companyName').value, this._user.id)
      .pipe(untilDestroyed(this),
        distinctUntilChanged(),
        switchMap(() => {
            this._user.companyName = this.form.get('companyName').value;

            return this._userCabinetService.createNewCode(
              {
                partnerFirstName: this._user.firstName,
                partnerLastName: this._user.lastName,
                partnerEmail: this._user.email,
                referralCode: this.form.get('AffiliateCode').value,
                partnerId: this._user.id
              }
            );
          }
        ),
    switchMap(val=>{

      const questionnaireList = responseList.map(item=>{
        item.userId = this._user.id

        return item;
      })

      return this._userCabinetService.sendQuestionnaire(questionnaireList)
    }))
      .subscribe(
        () => {
          this.showLoader$.next(false);

          this.showAffiliateWindow$.next('create');
        },
        (error) => {
          switch (error.error.errorCode) {
            case 3021:
              this.notification.showBlankNotification(
                'You cannot create more than 1 referral codes. ' +
                'Please contact support if you have any questions',
                TypeMessage.ERROR
              );
              break;
            default:
              this.notification.showBlankNotification(
                'Affiliate code already exist',
                TypeMessage.ERROR
              );
              break;
          }
          this.showLoader$.next(false);

        }
      );
  }

  getWindowInfo(): void {
    this.showLoader$.next(true);

    combineLatest([
      this._userCabinetService.getPartnershipInfo(),
      this._userCabinetService.getReferralCodesInfo()
    ]).pipe(
      map(([partnershipList, referralCodesList]) => ({
        partnershipList,
        referralCodesList
      }))
    )
      .pipe(untilDestroyed(this)).subscribe(value => {
      if (value.referralCodesList?.length > 0) {
        this.showLoader$.next(false);

        this.showAffiliateWindow$.next('exist');

        return;
      }

      switch (value.partnershipList[value.partnershipList.length - 1]?.status) {
        case PartnershipRequestStatus.Confirmed:
          this.showAffiliateWindow$.next('exist');
          break;
        case PartnershipRequestStatus.Pending:
          this.showAffiliateWindow$.next('pending');
          break;
        default:
          this.showAffiliateWindow$.next('');
          break;
      }
      this.showLoader$.next(false);

    });
  }

  next(): void {

    const form: FormGroup = this.form;
    this.form.get('companyName').setValue(this.form.get('companyName')?.value?.trim());

    if (!form.value.acceptAffiliateTermsConditions) {
      this.errors.acceptAffiliateTermsConditions = this.errorMessages.acceptAffiliateTermsConditions.required;
    }

    if (this.form.get('AffiliateCode').hasError('codeIsExist')) {
      this.errors.AffiliateCode =
        'This code is already taken, please insert a different code.';

      return;
    }

    if (
      form.invalid ||
      !form.value.acceptAffiliateTermsConditions
    ) {

      this.errors = this._errorFormService.verifyError(form, this.errorMessages);

      return;
    }

    if (this.form.get('companyName')?.hasError('minlength') ||
      this.form.get('companyName')?.hasError('required')) {
      this.errors.companyName = 'Min length 3 symbol';
      this.notification.showBlankNotification(
        'Company Name min length 3 symbol',
        TypeMessage.ERROR
      );

      return;

    }
    this.showLoader$.next(true);

    this.showAffiliateWindow$.next('questionnaire');

  }

  private readonly validateAffiliateCode = (control: AbstractControl) => {
    return this._userCabinetService.getReferral(control.value).pipe(
      untilDestroyed(this),
      catchError(() => of({} as IExistAffiliate)),
      map((res) => {
        return !res?.id ? null : { codeIsExist: 'reerer' };
      })
    );
  };

  private readonly validateExistAffiliateCode = (control: AbstractControl) => {
    return this._userCabinetService.getPartnershipValidate(control.value).pipe(
      untilDestroyed(this),
      catchError(() => of(false)),
      map((res) => {
        return res ? null : { codeIsExist: 'reerer' };
      })
    );
  };

  private readonly _trim = (
    control: FormControl
  ): ValidationErrors => {
    if (control?.value?.trim()?.length === 0) {
      control?.setValue(control?.value?.trim());

      return { required: true };
    } else {
      return null;
    }
  };

  private readonly _checkAcceptAffiliateTermsConditions = (
    control: FormControl
  ): ValidationErrors => {
    return control.value ? null : { notSame: true };
  };

}
