<div style='min-width: 360px; min-height: 360px;position:relative;'>

  <ng-container [ngSwitch]='showAffiliateWindow$|async'>
    <ng-container *ngSwitchCase="'create'">
      <div style='position: relative'>
        <lib-dialog-title [showIcon]='false'></lib-dialog-title>
        <lib-confirm-affiliate-window></lib-confirm-affiliate-window>
        <lib-loading *ngIf='showLoader$|async'></lib-loading>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'exist'">
      <lib-dialog-title [showIcon]='false'></lib-dialog-title>
      <lib-exist-affiliate-window></lib-exist-affiliate-window>
    </ng-container>
    <ng-container *ngSwitchCase="'pending'">
      <lib-dialog-title [showIcon]='false'></lib-dialog-title>
      <lib-pending-affiliate-window></lib-pending-affiliate-window>
    </ng-container>

    <ng-container *ngSwitchCase="'questionnaire'">
      <div style='position: relative; min-height: 360px'>
        <div
          class='primary-form sign-up-form' style='position:relative;'
        >
          <h2 class='title'>{{ 'please-answer' | translate }}</h2>
          <lib-questionnaire
            [form]='formQuestionnaire'
            [showLogin]='false'
            *ngIf='loadQuestionList$|async as loadQuestionList'
            [questions]='loadQuestionList'
            (sendFormEvent)='addNewCode($event)'

          ></lib-questionnaire>

        </div>
        <lib-loading *ngIf='showLoader$|async'></lib-loading>

      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf='!(showLoader$|async)'>

        <lib-dialog-title></lib-dialog-title>
        <div class='container-dialog'>
          <h2 class='title' style='margin-top: 3rem'>
            {{ 'add-new-affiliate-code' | translate }}
          </h2>
          <form (ngSubmit)='next()' [formGroup]='form'>
            <nz-form-item>
              <nz-form-control [nzErrorTip]='errors.AffiliateCode'>
                <input
                  nz-input
                  type='text'
                  name='AffiliateCode'
                  id='AffiliateCode'
                  class='primary-input'
                  formControlName='AffiliateCode'
                  placeholder='Affiliate code'
                />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-control [nzErrorTip]='errors.companyName'>
                <input
                  nz-input
                  type='text'
                  name='companyName'
                  id='companyName'
                  maxlength='35'
                  minlength='3'
                  class='primary-input'
                  formControlName='companyName'
                  placeholder='Company name'
                  [readOnly]='isCompanyName'
                />
              </nz-form-control>
            </nz-form-item>


            <nz-form-item>
              <nz-form-control [nzErrorTip]='errors.acceptAffiliateTermsConditions'>
                <div class='checkbox-block'>
                  <input
                    nz-input
                    type='checkbox'
                    name='acceptAffiliateTermsConditions'
                    id='acceptAffiliateTermsConditions'
                    class='primary-input'
                    formControlName='acceptAffiliateTermsConditions'
                  />
                  <label
                  >{{ 'i-have-read-and-agree-to-the' | translate }}
                    <a target='_blank' href='/assets/download/TPT Affiliate T&C.pdf'>{{
                      'affiliate-terms-conditions' | translate
                      }}</a></label
                  >
                </div>
              </nz-form-control>
            </nz-form-item>

            <button type='submit' class='primary-btn sign-up'>
              {{ 'next' | translate }}
            </button>
          </form>
        </div>
      </ng-container>
      <lib-loading *ngIf='showLoader$|async'></lib-loading>

    </ng-container>
  </ng-container>
</div>
