import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { User } from 'user';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs/internal/Observable';
import { IExistAffiliate, IPartnershipRequestStatus, IQuestionnaireQuestionDto } from 'repository';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UserCabinetService {
  constructor(
    private readonly user: User,
    private readonly router: Router,
    private readonly _http: HttpClient,
    @Inject(PLATFORM_ID) private readonly _platformId: object
  ) {
  }

  public signOut(): void {
    this.user
      .signOut()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.router.navigate(['']);
          if (isPlatformBrowser(this._platformId)) {
            window.location.reload();
          }
        },
        (error) => console.error('signOut error', error)
      );
  }

  getReferral(code: string): Observable<IExistAffiliate> {
    return this._http.get<IExistAffiliate>(
      `${environment.promocodesUrl}/api/ReferralCodes/${code}`
    );
  }

  getPartnershipValidate(code: string): Observable<boolean> {
    return this._http.post<boolean>(
      `${environment.promocodesUrl}/api/Partnership/validate`,{code}
    );
  }

  // tslint:disable-next-line:no-any
  createNewCode(body: {
    partnerFirstName: string,
    partnerLastName: string,
    partnerEmail: string,
    referralCode: string,
    partnerId: number
    // tslint:disable-next-line:no-any
  }): Observable<any> {
    // tslint:disable-next-line:no-any
    return this._http.post<any>(
      `${environment.promocodesUrl}/api/Partnership`,
      body
    );
  }

  // tslint:disable-next-line:no-any
  changeCompanyName(companyName: string, id: number): Observable<any> {
    return this._http.put<IExistAffiliate>(
      `${environment.identityUrl}/api/users/change-company-name`,
      {
        id,
        companyName
      }
    );
  }

  // tslint:disable-next-line:no-any
  getPartnershipInfo(): Observable<IPartnershipRequestStatus[]> {
    // tslint:disable-next-line:no-any
    return this._http.get<IPartnershipRequestStatus[]>(
      `${environment.promocodesUrl}/api/Partnership`
    );
  }

  // tslint:disable-next-line:no-any
  getReferralCodesInfo(): Observable<any[]> {
    // tslint:disable-next-line:no-any
    return this._http.get<IPartnershipRequestStatus[]>(
      `${environment.promocodesUrl}/api/ReferralCodes`
    );
  }

  getQuestionnaire(lang: number): Observable<IQuestionnaireQuestionDto[]> {
    return this._http.get<IQuestionnaireQuestionDto[]>(
      `${environment.promocodesUrl}/api/Questionnaire/?questionnaireLanguage=` + lang
    );
  }

  sendQuestionnaire(body:
                      {
                        questionnaireQuestionId: number,
                        answer: string,
                        userId: number
                      }[]
                    // tslint:disable-next-line:no-any
  ): Observable<any> {
    return this._http.post<IQuestionnaireQuestionDto[]>(
      `${environment.promocodesUrl}/api/Questionnaire`,
      body
    );
  }
}
