<form [formGroup]='form'
      class='primary-form sign-up-form'
      (ngSubmit)='test()'

>
  <nz-form-item *ngFor='let field of questions; let index = index'>
    <div class='label-block'>
      <div>{{index+1}}.</div>
      <div>{{field.question}}</div>
    </div>
    <nz-form-control [nzErrorTip]="errors['response_'+field.id]">
        <textarea
          rows='3'
          nz-input
          class='primary-input'
          [formControlName]="'response_' + field.id"
          placeholder='Your answer'
        ></textarea>
    </nz-form-control>
  </nz-form-item>

  <button type='submit' class='primary-btn sign-up'>
    {{ 'next-up' | translate }}
  </button>

  <div class='primary-flex-start login mt-4' *ngIf='showLogin'>
    <p class='info text'>{{ 'are-you-already-registered' | translate }}</p>
    <button
      type='button'
      class='primary-invisible text info-btn main-color'
      (click)='login()'
    >
      {{ 'please-login' | translate }}
    </button>
  </div>
</form>
